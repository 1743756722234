/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
import { useSelector } from 'react-redux'

import { getConnectedWalletAddress } from 'js/state/modules/app/selectors'
import { getStatsigClient } from 'js/state/modules/app/selectors'
import { EventAction, EvtCategory, sendGaEvent } from 'js/utils/events'
import { SimpleMap } from 'js/utils/types'

export type { EventAction } from 'js/utils/events'
export { EvtCategory } from 'js/utils/events'

const useEventTrackers = () => {
  const connectedAddress = useSelector(getConnectedWalletAddress)
  const statsigClient = useSelector(getStatsigClient)
  const sendEvent = (eventCategory: EvtCategory, eventAction: EventAction, args?: SimpleMap<string>, eventLabel?: string, eventValue?: number) => {
    // logging event in google analytics
    sendGaEvent(eventCategory, eventAction, eventLabel, eventValue, {
      ...connectedAddress && {
        address: connectedAddress,
      },
      ...args,
    })
    if (statsigClient) {
      // logging event in statsig if statsig initialized
      statsigClient.logEvent(eventAction, eventValue, {
        category: eventCategory,
        label: eventLabel ?? '',
        ...args
      })
    }
  }


  return {
    sendEvent,
  }
}
export default useEventTrackers
