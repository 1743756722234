import { Button, Divider, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { useRef } from 'react'

import { StyleUtils, useCommonStyles } from 'js/utils'
import { PartialBoxProps } from 'js/utils/component'

import AssetIcon from '../AssetIcon'
import DropdownButton from '../DropdownButton'
import NumberField from '../NumberField'
import { NumberFieldProps } from '../NumberField/NumberField'
import TextButton from '../TextButton'
import { ClassesMap } from '../TextField'
import TokenSymbol from '../TokenSymbol'

export type AmountFieldProps = NumberFieldProps & PartialBoxProps & {
  suffix?: any
  boxClass?: string
  inputRootClass?: string
  inputClasses?: ClassesMap
  errorText?: string
  subValue?: React.ReactNode
  assetDenom?: string
  assetLabel?: string
  showIcon?: boolean
  isMinted?: boolean
  hasDropdown?: boolean
  showDropdown?: boolean
  onClickDropdown?: () => void
  rightAligned?: boolean
  fieldButtonTitle?: string
  fieldButtonLabelClass?: string
  fieldButtonClass?: string
  onFieldButtonClick?: () => void
  noDivider?: boolean
  disableRipple?: boolean
}

const AmountField: React.FC<AmountFieldProps> = (props: AmountFieldProps) => {
  const { suffix, boxClass, inputRootClass, inputClasses,
    errorText, subValue, assetDenom, assetLabel, showIcon = true, isMinted = false,
    hasDropdown = false, showDropdown = false, onClickDropdown,
    rightAligned = false, fieldButtonTitle, fieldButtonLabelClass, fieldButtonClass, onFieldButtonClick, noDivider = false, disableRipple = false,
    placeholder, onFocus, ...rest } = props

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const inputRef = useRef<HTMLInputElement>()

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (inputRef.current) { // force cursor to be on the right side of input due to textAlign right
      const inputLength = inputRef.current.value.length
      inputRef.current.type = 'text'
      inputRef.current.setSelectionRange(inputLength, inputLength)
      inputRef.current.type = 'number'
    }

    if (onFocus) {
      onFocus(event)
    }
  }

  const getInputTokenDisplay = (): React.ReactNode => {
    return (
      <div className={classes.label}>
        {showIcon && <AssetIcon className={classes.icon} svgClass={classes.assetIcon} denom={assetDenom} isMinted={isMinted} />}
        {assetLabel ? assetLabel : <TokenSymbol denom={assetDenom} showAssetTitle symbolClass={classes.tokenSymbolClass} />}
      </div>
    )
  }

  const getInputSuffix = (): React.ReactNode | null => {
    if (suffix) return (
      <div className={commonClasses.alignItemsCenter}>
        {fieldButtonTitle && onFieldButtonClick && (
          <React.Fragment>
            <Button disableRipple={disableRipple} variant="text" color="primary" onClick={onFieldButtonClick} className={clsx(classes.inputLabelBtn, fieldButtonClass)}>
              <TextButton label={fieldButtonTitle} labelClass={fieldButtonLabelClass} />
            </Button>
            {!noDivider && <Divider orientation="vertical" flexItem className={classes.divider} />}
          </React.Fragment>
        )}
        {suffix}
      </div>
    )

    if (rightAligned && subValue) {
      return (
        <div className={classes.usdValueLabel}>
          {subValue}
        </div>
      )
    }
    if (!rightAligned && assetDenom) {
      return (
        <div className={commonClasses.alignItemsCenter}>
          {fieldButtonTitle && onFieldButtonClick && (
            <React.Fragment>
              <Button disableRipple={disableRipple} variant="text" color="primary" onClick={onFieldButtonClick} className={clsx(classes.inputLabelBtn, fieldButtonClass)}>
                <TextButton label={fieldButtonTitle} labelClass={fieldButtonLabelClass} />
              </Button>
              {!noDivider && <Divider orientation="vertical" flexItem className={classes.divider} />}
            </React.Fragment>
          )}
          {hasDropdown && (
            <DropdownButton
              className={classes.tokenSelect}
              open={showDropdown}
              onClick={onClickDropdown}
              containerClass={classes.containerClass}
              disableRipple
            >
              {getInputTokenDisplay()}
            </DropdownButton>
          )}
          {!hasDropdown && getInputTokenDisplay()}
        </div>
      )
    }
    return null
  }

  const getInputPrefix = (): React.ReactNode | null => {
    if (rightAligned && assetDenom) {
      return (
        <div className={clsx(commonClasses.alignItemsCenter, classes.marginLeft1)}>
          {fieldButtonTitle && onFieldButtonClick && (
            <React.Fragment>
              <Button disableRipple={disableRipple} variant="text" color="primary" onClick={onFieldButtonClick} className={clsx(classes.inputLabelBtn, fieldButtonClass)}>
                <TextButton label={fieldButtonTitle} labelClass={fieldButtonLabelClass} />
              </Button>
              {!noDivider && <Divider orientation="vertical" flexItem className={classes.divider} />}
            </React.Fragment>
          )}
          {hasDropdown && (
            <DropdownButton
              className={classes.tokenSelect}
              open={showDropdown}
              onClick={onClickDropdown}
              containerClass={classes.containerClass}
              disableRipple
            >
              {getInputTokenDisplay()}
            </DropdownButton>
          )}
          {!hasDropdown && getInputTokenDisplay()}
        </div>
      )
    }

    if (!rightAligned && subValue) {
      return (
        <div className={classes.usdValueLabel}>
          {subValue}
        </div>
      )
    }

    return null
  }

  return (
    <div className={clsx(classes.amountInputBox, boxClass)}>
      <NumberField
        inputProps={{
          min: 0,
          className: clsx(classes.inputWrapper, rightAligned ? classes.inputRightAligned : classes.inputLeftAligned, subValue && classes.primaryValue),
          onKeyDown: (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault(),
          ref: inputRef,
        }}
        inputClasses={{
          outlined: {
            root: clsx(classes.root, classes.inputRoot, inputRootClass),
            adornedEnd: classes.adornedEnd,
            ...inputClasses?.outlined
          },
          label: clsx(classes.inputLabel, inputClasses?.label),
          startAdornment: clsx(classes.startAdornment, !rightAligned && classes.prefixLeftAligned, inputClasses?.startAdornment),
          endAdornment: clsx(classes.endAdornment, rightAligned && classes.suffixRightAligned, inputClasses?.endAdornment),
        }}
        prefix={getInputPrefix()}
        suffix={getInputSuffix()}
        onFocus={handleOnFocus}
        errorText={errorText}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      height: theme.spacing(5.25),
    },
  },
  amountInputBox: {
    marginTop: theme.spacing(0.5),
  },
  inputRoot: {
    display: 'inline-flex',
    padding: theme.spacing(1.5, 2),
  },
  inputWrapper: {
    padding: theme.spacing(0),
    maxWidth: '55%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '40vw',
    },
  },
  inputLeftAligned: {
    textAlign: 'left',
    marginRight: 'auto',
    paddingRight: theme.spacing(1)
  },
  inputRightAligned: {
    textAlign: 'right',
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1)
  },
  primaryValue: {
    marginBottom: theme.spacing(1.75),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  startAdornment: {
    position: 'absolute',
    left: theme.spacing(0),
    height: 'unset',
    margin: theme.spacing(0),
  },
  prefixLeftAligned: {
    left: theme.spacing(2),
    top: theme.spacing(3.25),
    [theme.breakpoints.only('xs')]: {
      top: theme.spacing(2.75),
    },
  },
  endAdornment: {
    margin: theme.spacing(0),
    height: 'unset',
  },
  suffixRightAligned: {
    position: 'absolute',
    top: theme.spacing(3.25),
    right: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      top: theme.spacing(2.75),
    },
  },
  usdValueLabel: {
    ...theme.typography.body5,
    fontSize: '9.5px',
    width: '100%',
    color: theme.palette.text.secondary,
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    width: 'unset',
  },
  adornedEnd: {
    paddingRight: theme.spacing(2),
  },
  tokenSelect: {
    width: 'unset !important',
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  containerClass: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'unset',
      alignItems: 'center',
    },
  },
  label: {
    ...theme.typography.body3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assetIcon: {
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1.5),
  },
  icon: {
    alignSelf: 'center',
    '&.error': {
      '& path': {
        stroke: 'url(#accentError)',
      },
    },
    '&.warning': {
      '& path': {
        fill: 'url(#accentWarning)',
      },
    },
    '&.info': {
      '& path': {
        stroke: theme.palette.text.demexSolid,
      },
    },
    '&.success': {
      '& path': {
        stroke: 'url(#accentSuccess)',
      },
    },
  },
  inputLabelBtn: {
    minWidth: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      outline: 'none !important',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
  },
  divider: {
    color: theme.palette.divider,
    alignSelf: 'center',
    margin: theme.spacing(0, 0.875, 0, 0.5),
    height: theme.spacing(3.75),
    [theme.breakpoints.only('xs')]: {
      height: theme.spacing(3),
    },
  },
  marginLeft1: {
    marginLeft: theme.spacing(1),
  },
  tokenSymbolClass: {
    ...StyleUtils.tokenSymbolOverflow,
  }
}))

export default AmountField
