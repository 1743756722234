import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { CDPReducer } from 'js/state/modules/cdp/reducer'
import { stakeReducer } from 'js/state/modules/stake/reducer'
import { StakeState } from 'js/state/modules/stake/types'

import { accountReducer } from './account/reducer'
import { AccountState } from './account/types'
import { alertsReducer } from './alerts/reducer'
import { AlertsState } from './alerts/types'
import { appReducer } from './app/reducer'
import { AppState } from './app/types'
import { bridgeReducer } from './bridge/reducer'
import { BridgeState } from './bridge/types'
import { CDPState } from './cdp/types'
import { CDPLiquidationReducer } from './cdpLiquidations/reducer'
import { CDPLiquidationsState } from './cdpLiquidations/types'
import { competitionReducer } from './competition/reducer'
import { CompetitionState } from './competition/types'
import { crossSellingReducer } from './crossSelling/reducer'
import { CrossSellingState } from './crossSelling/types'
import { erc20Reducer } from './erc20/reducer'
import { Erc20State } from './erc20/types'
import { evmWsReducer } from './evmWebSocket/reducer'
import { EvmWsState } from './evmWebSocket/types'
import { exchangeReducer } from './exchange/reducer'
import { ExchangeState } from './exchange/types'
import { tradingFeesReducer } from './feetiers/reducer'
import { FeeTiersState } from './feetiers/types'
import { HistoryReducer } from './history/reducer'
import { HistoryState } from './history/types'
import { inboxWsReducer } from './inboxWebSocket/reducer'
import { InboxWsState } from './inboxWebSocket/types'
import { leaderboardReducer } from './leaderboard/reducer'
import { LeaderboardState } from './leaderboard/types'
import { leveragesReducer } from './leverages/reducer'
import { LeveragesState } from './leverages/types'
import { poolsReducer } from './liquidityPool/reducer'
import { PoolsState } from './liquidityPool/types'
import loadingTaskReducer, { LayoutState } from './loadingTask/reducer'
import { marketStatsReducer } from './marketStats/reducer'
import { MarketStatsState } from './marketStats/types'
import { orderBookReducer } from './orderBook/reducer'
import { OrderBookState } from './orderBook/types'
import { OrderManagerReducer } from './orderManager/reducer'
import { OrderManagerState } from './orderManager/types'
import { pricingReducer } from './pricing/reducer'
import { PricingState } from './pricing/types'
import { recentTradesReducer } from './recentTrades/reducer'
import { RecentTradesState } from './recentTrades/types'
import { referralReducer } from './referrals/reducer'
import { ReferralState } from './referrals/types'
import { externalWsReducer } from './spotHistory/reducer'
import { ExternalWsState } from './spotHistory/types'
import { vaultReducer } from './vaults/reducer'
import { VaultState } from './vaults/types'
import { WalletBalanceReducer } from './walletBalance/reducer'
import { WalletBalanceState } from './walletBalance/types'
import { wsReducer } from './webSocket/reducer'
import { WsState } from './webSocket/types'
import { welcomeOfferReducer } from './welcomeOffer/reducer'
import { WelcomeOfferState } from './welcomeOffer/types'

export interface RootState {
  router: RouterState,
  app: AppState,
  account: AccountState
  competition: CompetitionState
  crossSelling: CrossSellingState
  exchange: ExchangeState
  recentTrades: RecentTradesState
  orderBook: OrderBookState
  orderManager: OrderManagerState
  pricing: PricingState
  history: HistoryState
  walletBalance: WalletBalanceState
  marketStats: MarketStatsState
  leaderboard: LeaderboardState
  leverages: LeveragesState
  pools: PoolsState
  stake: StakeState
  ws: WsState
  evmWs: EvmWsState
  layout: LayoutState
  cdp: CDPState
  cdpLiquidations: CDPLiquidationsState
  referrals: ReferralState
  feeTiers: FeeTiersState
  vaults: VaultState
  welcomeOffer: WelcomeOfferState
  erc20: Erc20State
  alerts: AlertsState
  inboxWs: InboxWsState
  externalWs: ExternalWsState,
  bridge: BridgeState,
}

const rootReducer = (history: History) => combineReducers<RootState>({
  router: connectRouter(history),
  app: appReducer,
  account: accountReducer,
  competition: competitionReducer,
  crossSelling: crossSellingReducer,
  exchange: exchangeReducer,
  recentTrades: recentTradesReducer,
  orderBook: orderBookReducer,
  orderManager: OrderManagerReducer,
  pricing: pricingReducer,
  history: HistoryReducer,
  walletBalance: WalletBalanceReducer,
  marketStats: marketStatsReducer,
  leaderboard: leaderboardReducer,
  leverages: leveragesReducer,
  pools: poolsReducer,
  stake: stakeReducer,
  ws: wsReducer,
  evmWs: evmWsReducer,
  layout: loadingTaskReducer,
  cdp: CDPReducer,
  cdpLiquidations: CDPLiquidationReducer,
  referrals: referralReducer,
  feeTiers: tradingFeesReducer,
  vaults: vaultReducer,
  welcomeOffer: welcomeOfferReducer,
  erc20: erc20Reducer,
  alerts: alertsReducer,
  inboxWs: inboxWsReducer,
  externalWs: externalWsReducer,
  bridge: bridgeReducer,
})

export default rootReducer
