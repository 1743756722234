import { makeStyles } from '@material-ui/core'
import { BlockchainUtils, MetaMask, MetaMaskSyncResult } from 'carbon-js-sdk'
import { EVMChain } from 'carbon-js-sdk/lib/util/blockchain'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import AlertBanner from 'js/components/Common/AlertBanner'
import Button from 'js/components/Common/Button'
import LoadingIcon from 'js/components/Common/LoadingIcon'
import SvgIcon from 'js/components/Common/SvgIcon'
import { useAsyncTask, useMetaMask } from 'js/hooks'
import { getNet, getThemeType } from 'js/state/modules/app/selectors'
import { useCommonStyles } from 'js/utils'
import { getEvmChainName } from 'js/utils/strings'

import { ReactComponent as ErrorDark } from 'assets/Error_Dark.svg'
import { ReactComponent as ErrorLight } from 'assets/Error_Light.svg'


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onConnect: (m: MetaMask | null) => void
  requiredChain: EVMChain | string
}

const GuardBlockchain: React.FC<Props> = (props: Props) => {
  const { className, requiredChain, onConnect, children, ...rest } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const network = useSelector(getNet)
  const theme = useSelector(getThemeType)
  const [runQueryNetwork] = useAsyncTask('queryNetwork')
  const [runChangeNetwork, loadingChangeNetwork] = useAsyncTask('runChangeNetwork')
  const [metamaskNetwork, setMetamaskNetwork] = useState<MetaMaskSyncResult>({})

  const { metamask, registerListener, registerListenerWallet } = useMetaMask(network)

  useEffect(() => {
    const deregister = registerListener(async () => {
      if (!metamask) return
      const result = await metamask.syncBlockchain()
      setMetamaskNetwork(result)
      onConnect(metamask)
    })
    return () => {
      deregister()
    }
  }, [metamask, registerListener]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const deregister = registerListenerWallet(async () => {
      onConnect(null)
    })
    return () => deregister()
  }, [metamask, registerListenerWallet, onConnect])

  const requiredChainOverride = useMemo(() => {
    let requiredChainOverride = requiredChain
    if (typeof requiredChain === 'string') {
      if (requiredChain.includes('Mantle')) {
        requiredChainOverride = 'Mantle'
      }
      if (requiredChain.includes('BSC')) {
        requiredChainOverride = 'Binance Smart Chain'
      }
    }
    return requiredChainOverride
  }, [requiredChain])

  const onChangeNetwork = () => {
    runChangeNetwork(async () => {
      if (!BlockchainUtils.isEvmChain(requiredChainOverride) || !metamask) return
      const param = MetaMask.getNetworkParams(network, requiredChainOverride ?? 'Ethereum')
      if (requiredChainOverride === 'Ethereum') {
        (await metamask.getConnectedAPI())?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: param.chainId }],
        })
        return
      }
      (await metamask.getConnectedAPI())?.request({
        method: 'wallet_addEthereumChain',
        params: [param],
      })
    })
  }

  const {
    requiredBlockchain,
  } = useMemo(() => {
    return {
      connectedBlockchain: getEvmChainName(metamaskNetwork.blockchain) ?? metamaskNetwork.blockchain,
      connectedChainId: metamaskNetwork.chainId,
      requiredBlockchain: getEvmChainName(requiredChain) ?? requiredChain,
    }
    // eslint-disable-next-line
  }, [metamaskNetwork, requiredChain])

  useEffect(() => {
    runQueryNetwork(async () => {
      if (!metamask) return
      try {
        const result = await metamask.syncBlockchain()
        setMetamaskNetwork(result)
      } catch (err) {
        console.error(err)
      }
    })

    // eslint-disable-next-line
  }, [metamask])

  if (Object.keys(metamaskNetwork).length === 0) {
    return (
      <div className={clsx(commonClasses.justifyContentCenter, classes.marginTop3)} >
        <LoadingIcon size="3em" />
      </div>
    )
  }

  if (MetaMask.getRequiredChainId(network, requiredChainOverride) === metamaskNetwork.chainId) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >
      <div className={classes.formBox}>
        <SvgIcon className={classes.networkSvg} component={theme === 'light' ? ErrorLight : ErrorDark} />
        <AlertBanner
          variant='error'
          title="Invalid MetaMask network."
          message={(
            <span>Please make sure you are connected to <strong>{requiredBlockchain}</strong>.</span>
          )}
          allowCollapse={false}
        />
      </div>
      {BlockchainUtils.isEvmChain(requiredChainOverride) && (
        <div className={clsx(commonClasses.justifyContentEnd, classes.marginTop1)}>
          <Button
            color="primary"
            disableElevation
            baseButtonVariant="contained"
            onClick={onChangeNetwork}
            classes={{ root: classes.actionButton }}
            loading={loadingChangeNetwork}
          >
            Change Network
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    borderRadius: 2,
    minHeight: '1.5rem',
    padding: theme.spacing(1.25, 3),
    minWidth: '7rem',
    '& > span': {
      minHeight: '1.5rem',
    },
  },
  formBox: {
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(3, 5),
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3, 3.5),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3, 2.5),
    },
  },
  networkSvg: {
    display: 'block',
    margin: theme.spacing(0, 'auto', 2),
    maxWidth: '9.25em',
    opacity: 0.95,
  },
  root: {
    // marginTop: theme.spacing(2),
    '& $.MuiAlertMessage': {
      width: '100%',
    },
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
}))

export default GuardBlockchain
