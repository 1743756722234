import BigNumber from 'bignumber.js'
import { AddressUtils, BlockchainUtils, CarbonSDK, CarbonSignerTypes, Hydrogen, TypeUtils } from 'carbon-js-sdk'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'

import { ONE_SWTH_FEE, TransferKey, TransferMethod, TransferType, tokenStandards } from 'js/constants/TransferOptions'
import { tokenAddrRegex } from 'js/constants/assets'

import { capitalize, chainLabel } from './strings'

export const availableNets: CarbonSDK.Network[] = [CarbonSDK.Network.MainNet, CarbonSDK.Network.DevNet]

export const blockchainConfig: TypeUtils.SimpleMap<BlockchainInfo> = {
  Ethereum: {
    maxEstimatedBlocks: new BigNumber(96),
    blockTime: new BigNumber(0.2), // 12 seconds
  },
  Arbitrum: {
    maxEstimatedBlocks: new BigNumber(1500),
    blockTime: new BigNumber(0.00583), // 0.35 seconds
  },
  Polygon: {
    maxEstimatedBlocks: new BigNumber(500),
    blockTime: new BigNumber(0.0383), // 2.3 seconds
  },
}

export const estimatedTimeGap: TypeUtils.SimpleMap<number> = {
  default: 2,
  Arbitrum: 21,
}

export interface BlockchainInfo {
  maxEstimatedBlocks: BigNumber
  blockTime: BigNumber
}


export function getIbcTransferDenom(externalTransfer: Hydrogen.CrossChainTransfer, sdk: CarbonSDK | undefined) {
  if (!sdk?.token) return ''
  const fromReg = externalTransfer.from_asset.match(tokenAddrRegex)
  let asset = ''
  if (fromReg) {
    asset = `ibc/${(fromReg?.[1] ?? '').toUpperCase()}`
  } else {
    const toReg = externalTransfer.to_asset.match(tokenAddrRegex)
    if (toReg) {
      asset = `ibc/${(toReg?.[1] ?? '').toUpperCase()}`
    } else {
      asset = externalTransfer.to_asset
    }
  }
  return asset
}

export function getTransferDenom(externalTransfer: Hydrogen.CrossChainTransfer, sdk: CarbonSDK | undefined, isIBC: boolean = false) {
  if (!sdk?.token) return ''
  if (externalTransfer.carbon_token_id && externalTransfer.carbon_token_id !== '') {
    return sdk.token.tokenForId(externalTransfer.carbon_token_id)?.denom ?? ''
  }
  if (isIBC) {
    return getIbcTransferDenom(externalTransfer, sdk)
  }

  try {
    // Check if this is a SWTH address (either from_address or to_address is the swth address. then get the token denom for swth side token)
    const fromAddressBytes = AddressUtils.SWTHAddress.getAddressBytes(externalTransfer.from_address, sdk.network)
    if (fromAddressBytes && fromAddressBytes.length > 1) {
      const asset = externalTransfer.from_asset
      const tokenForId = sdk.token.tokenForId(asset)
      return tokenForId?.denom ?? ''
    }
  } catch (err) {
    const asset = externalTransfer.to_asset
    const tokenForId = sdk.token.tokenForId(asset)
    return tokenForId?.denom ?? ''
  }
  return ''
}

export function getTransferStatusLabel(status: Hydrogen.CrossChainFlowStatus) {
  switch (status) {
    case Hydrogen.CrossChainFlowStatus.Completed:
      return 'Completed'
    case Hydrogen.CrossChainFlowStatus.Refunded:
      return 'Refunded'
    case Hydrogen.CrossChainFlowStatus.FailedAndNotRecoverable:
      return 'Failed'
    default:
      return 'In Transit'
  }
}

export function getTransferType(externalTransfer: Hydrogen.CrossChainTransfer, walletAddress: string) {
  if (externalTransfer.to_address === walletAddress) {
    return TransferType.Deposit
  }
  return TransferType.Withdraw
}

export function getTransferLabelText(status: Hydrogen.CrossChainFlowStatus) {
  switch (status) {
    case Hydrogen.CrossChainFlowStatus.Completed:
      return 'Success'
    case Hydrogen.CrossChainFlowStatus.Refunded:
      return 'Refunded'
    case Hydrogen.CrossChainFlowStatus.FailedAndNotRecoverable:
      return 'Failed'
    default:
      return 'In Transit'
  }
}

export function generateTransferOptionData(blockchain: BlockchainUtils.BlockchainV2, transferMethod: TransferMethod, transferKey: TransferKey) {
  let defaultForSigner: CarbonSignerTypes = CarbonSignerTypes.PublicKey
  switch (transferMethod) {
    case TransferMethod.Token:
      defaultForSigner = CarbonSignerTypes.PrivateKey
      break
    case TransferMethod.Ledger:
      defaultForSigner = CarbonSignerTypes.Ledger
      break
    case TransferMethod.MetaMask:
      defaultForSigner = CarbonSignerTypes.PrivateKey
      break
    case TransferMethod.TradeHub:
      defaultForSigner = CarbonSignerTypes.PrivateKey
      break
    default:
      defaultForSigner = CarbonSignerTypes.PublicKey
      break
  }

  return {
    key: transferKey,
    transferMethod,
    blockchain,
    defaultForSigner,
    label: `${chainLabel(blockchain)} (via ${transferMethod})`,
    tokenType: tokenStandards[blockchain] ?? capitalize(blockchain),
    fee: {
      withdraw: ONE_SWTH_FEE,
    },
  }
}

export const isNeoBlockchain = (chain: BlockchainUtils.BlockchainV2 | undefined) => {
  if (!chain) return false
  return ['neo', 'neo3'].includes(chain.toLowerCase())
}

/**
* Calculates the estimated time for a deposit to be processed based on the selected blockchain.
* @param {string | undefined} blockchain - The name of the blockchain to estimate the deposit time for. Accepted values are 'Ethereum', 'Arbitrum', and 'Polygon'.
* @returns {{ estimatedTime: BigNumber }} An object containing the estimated time in seconds (in BigNumber format) for a deposit to be processed.
*/
export function getDepositEstimatedTime(blockchain: string | undefined) {
  const MAX_ESTIMATED_BLOCKS = bnOrZero(blockchainConfig[blockchain ?? '']?.maxEstimatedBlocks)
  const BLOCK_TIME = bnOrZero(blockchainConfig[blockchain ?? '']?.blockTime)

  return {
    estimatedTime: MAX_ESTIMATED_BLOCKS.multipliedBy(BLOCK_TIME),
  }
}

// Flag for disabling polynetwork deposits and withdraws
export const DISABLE_POLYNETWORK_DEPOSIT = false
export const DISABLE_POLYNETWORK_WITHDRAW = false
